import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type * as CSS from 'csstype';
import React, { useEffect, useRef } from 'react';
import type { PropsWithChildren } from 'react';
import type { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

export type IconDefinition = IconProp;

export type BaseIconProps<T extends IconDefinition> = {
  icon?: T
} & RenderIconProps;


export type IconDefaultProps = {
  icon?: IconDefinition
}

type CommunIconProps = {
  component?: React.ElementType
  square?: boolean
  iconProps?: Omit<FontAwesomeIconProps, "icon">
  className?: string
  buttonProps?: JSX.IntrinsicElements['div']
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void
}

type RenderAsIconProps = {
  asButton?: false
}

type RenderAsButtonIconProps = {
  asButton: true
}

export type RenderIconProps =
  CommunIconProps & (RenderAsIconProps | RenderAsButtonIconProps);

export const RenderIcon = ({
  component = 'span',
  asButton = false,
  onClick, // = (e: React.MouseEvent<any>) => { },
  square = true,
  children,
  buttonProps,
  ...props
}: PropsWithChildren<RenderIconProps>) => {

  const iconRef = useRef<HTMLButtonElement>(null);

  // We need to wait for the display (= having a width) to set the height
  useEffect(() => {
    const { current } = iconRef;
    if (current && square && asButton) {
      // onVisible(current, () => {
      //   const { offsetWidth, clientWidth } = current;
      //   const max = Math.max(offsetWidth, clientWidth)
      //   if (max > 0) {
      //     current.style.height = `${max}px`;
      //   }
      // })
    }
  }, [square, asButton]);

  const compProps = {
    onClick,
    ...(asButton ? buttonProps : undefined),
    ...props,
    children
  }

  if (typeof component === 'string') {
    return React.createElement(component, compProps);
  } else {
    const Comp = component;
    return <Comp {...compProps} />
  }
}

type OneIconProps<Icons extends IconName> = FontAwesomeIconProps & {
  // icon: Icons,
  // color?: CSS.Property.Color,
  // transform?: FontAwesomeIconProps['transform'],
  // style?: FontAwesomeIconProps['style'],
  subClassName?: string
}

export type IconDoubleProps<Icons extends IconName> = {
  BgIconProps: OneIconProps<Icons>
  FgIconProps: OneIconProps<Icons> | OneIconProps<Icons>[]
  defaultColor?: CSS.Property.Color
} & RenderIconProps;

export function IconDouble({
  BgIconProps,
  FgIconProps,
  defaultColor = 'inherit',
  className,
  ...props
}: IconDoubleProps<IconName> & {

}) {

  BgIconProps = {
    ...{
      icon: 'industry',
      color: defaultColor,
      transform: {
        size: 12,
        y: -2,
        x: -3,
      },
      subClassName: 'bg-icon',
    },
    ...BgIconProps
  };

  if (typeof FgIconProps === 'object' && !('length' in FgIconProps)) {
    FgIconProps = [FgIconProps];

  }

  const innerFgIconProps = FgIconProps.map(single => ({
    ...{
      icon: 'industry',
      // color: BgIconProps.color ? theme.palette.getContrastText(BgIconProps.color) : defaultColor,
      transform: {
        size: 8,
        y: 3,
        x: 3,
      },
      subClassName: 'fg-icon',
    },
    ...single
  }));

  return (<RenderIcon
    className={clsx("fa-layers", className)}
    {...props}>
    <>
      {[BgIconProps, ...innerFgIconProps].map((Obj, i) => {
        const { color, style, transform, subClassName, className, ...ObjProps } = Obj;
        return (<FontAwesomeIcon
          key={i}
          style={{
            color: color,
            ...style,
          }}
          transform={transform}
          {...ObjProps}
          className={clsx(className,
            Obj.subClassName ?? 'icon', `${(Obj.subClassName ?? 'icon')}-${i}`)}
        />)
      })}
    </>
  </RenderIcon>);
}

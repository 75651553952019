import type { IconName } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "@fortawesome/fontawesome-svg-core"
import { useState } from "react";
import type { IconDoubleProps } from "~/icons";
import { IconDouble } from "~/icons"

type AppIconProps = Omit<IconDoubleProps<IconName>, "BgIconProps" | "FgIconProps"> & {
  BgIconProps?: IconDoubleProps<IconName>['BgIconProps']
  FgIconProps?: IconDoubleProps<IconName>['FgIconProps']
  spin?: boolean
}
export default function AppIcon({
  className,
  BgIconProps = {
    icon: 'display',
    transform: {
      x: 0,
      y: 0
    },
    // subClassName: "text-primary"
  },
  FgIconProps = {
    icon: 'dice-six',
    transform: {
      x: 1,
      y: -1.5,
      size: 6,
    },
    className: 'fa-rotate-by',
    style: {
      // @ts-ignore
      '--fa-rotate-angle': '45deg',
    }
  },
  spin = true,
  ...props
}: AppIconProps) {

  const [innerSpin, setSpin] = useState(spin);


  if (!Array.isArray(FgIconProps)) {
    FgIconProps.spin = innerSpin;
    if ((new Date()).toISOString().includes('-04-01T')) {
      FgIconProps.icon = 'fish';
      FgIconProps.spin = false;
      FgIconProps.bounce = true;
    }
  }

  const handleClick = (e: React.MouseEvent<any>) => {
    if (e.type === 'click' && e.ctrlKey) {
      setSpin(p => !p);
    }
  }

  return <IconDouble
    {...props}
    onClick={handleClick}
    className={className}
    BgIconProps={BgIconProps}
    FgIconProps={{
      ...FgIconProps,
    }}
  // {...props}
  />

}
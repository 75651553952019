import type { PropsWithChildren } from "react";
import { useEffect, useRef } from "react";
import AppIcon from "~/components/Icons/AppIcon";
import { getPseudo } from "~/helpers/string";
import { createUser, verifyLogin } from "~/models/user.server";
import type { ActionFunctionArgs, HeadersFunction, LoaderFunctionArgs, MetaFunction } from "~/remix";
import { Link, redirect, useLocation, useParams } from "~/remix";
import { getClientFingerprintFromCookie, getServerFingerprint, useRootLoaderData } from "~/root";
import { createUserSession, getSession, getUser } from "~/session.server";
import { useOptionalUser } from "~/utils";
import BaseLayout from "./__layout";
import type { PropsWithChildrenAndClassName } from "~/types/react";
import clsx from "clsx";
import { combineServerTimings } from "~/helpers/timings.server";
import isbot from "isbot";

export const headers: HeadersFunction = ({ loaderHeaders, parentHeaders, actionHeaders }) => {
  const headers = {
    'Server-Timing': combineServerTimings(loaderHeaders, parentHeaders, actionHeaders),
  }
  return headers;
}


export const meta: MetaFunction<typeof loader,
  { "root": typeof useRootLoaderData }
> = ({ matches }) => {

  const root = matches.find(
    (match) => match.id === "root"
  )?.data;

  return [{
    title: `Une petite partie ochestrée par ${root?.SITE_NICKNAME ?? 'MOSARpg'}?`,
  }];
};


export async function loader({ request }: LoaderFunctionArgs) {
  const user = await getUser(request);
  if (user) {
    throw redirect('./dashboard')
  }
  return true;
}

export async function action({ request, context }: ActionFunctionArgs) {

  if (isbot(request.headers.get("user-agent"))) {
    throw new Error("No bot here");
  }

  const throwRedirect = async () => {
    throw redirect('/', {
      ...await session.commitSessionHeader(),
    });
  }

  const datas = Object.fromEntries(await request.formData());

  const session = await getSession(request);
  const redirectTo = String(datas.redirectTo ?? session.getRedirectTo() ?? '/dashboard');

  if (!datas.invite || datas.invite !== 'invite') {
    throwRedirect();
  }


  const serverFp = getServerFingerprint(request, context);
  const clientFp = getClientFingerprintFromCookie(request);

  const {
    sf,
    cf,
  } = datas;

  if (sf !== serverFp || cf !== clientFp) {
    throwRedirect();
  }

  const password = 'zzzzzzzz';
  const displayName = getPseudo();
  const email = `${sf}-${cf}`;

  const user1 = await verifyLogin(email, password);

  if (user1) {
    return createUserSession({
      request,
      userId: user1.id,
      remember: false,
      redirectTo: redirectTo,
    });
  }

  const user = await createUser(email, password, String(displayName));

  return createUserSession({
    request,
    remember: true,
    redirectTo,
    userId: user.id,
    cookieDurationInDay: 1,
  });
}

export const IndexContent = ({
  children,
  className = '?',
}: PropsWithChildrenAndClassName<{}>) => {
  const user = useOptionalUser();
  const { SITE_BASENAME, SITE_NICKNAME } = useRootLoaderData()

  return <>
    {user ? (
      <Link
        className="btn btn-primary"
        to="./dashboard"

      >
        Dashboard
      </Link>
    ) : (
      <div
        className={clsx(className.replace('?', "grid grid-cols-1 md:grid-cols-2 gap-4 "))}
      >
        <h1 className="text-5xl md:text-7xl font-bold text-primary 
        md:col-span-2 
        text-center">
          <div className="flex flex-row gap-x-4 justify-start items-center leading-9 md:leading-[inherit]">
            <AppIcon />
            <div className="flex flex-col items-center md:items-start">
              <span className="">{SITE_NICKNAME}</span>
              <span className={`text-accent
              -order-1 text-xs md:text-[.85rem]
              md:order-1 md:-mt-2 md:ml-1
              
              `}>{SITE_BASENAME}</span>
            </div>
          </div>
        </h1>
        {children ?? <>

          <Link
            to="./login"
            className="btn btn-primary btn-outline"
          >
            S'identifier
          </Link>
          <Link
            className="btn btn-outline btn-secondary"
            to="./join"
          >
            Se créer un compte
          </Link>

          <InviteModeButton />
        </>}


      </div>
    )}
  </>
}

export const InviteModeButton = () => {

  const datas = useRootLoaderData()
  const sp = new URLSearchParams(useLocation().search);
  const ref = useRef<HTMLInputElement>(null)

  const defineCF = () => {
    if (ref.current) {
      const [, cf] = document.cookie.match(/cf=(\w*)/) ?? [null, null];
      ref.current.value = String(cf)
    }
  }

  useEffect(() => {
    const handleCf = (e: Event) => {
      if (e instanceof CustomEvent) {
        const { detail } = e;
        if (detail && ref.current) {
          ref.current.value = detail;
        }
      }
    }
    document.addEventListener('cf', handleCf);
    return () => {
      document.removeEventListener('cf', handleCf)
    }
  }, [])

  useEffect(() => {
    defineCF()
  }, [])

  return <div className="dropdown dropdown-top md:col-span-2 w-full">
    <label tabIndex={0} className="btn btn-ghost w-full md:col-span-2" onClick={defineCF}>Mode invité</label>
    <div tabIndex={-1} className="dropdown-content z-[1] card card-compact p-2 shadow bg-primary text-primary-content w-full -mb-14 rounded-md">
      <div className="card-body">
        <h3 className="card-title">Envie de tester?</h3>
        <p>Vous ne voulez pas vous créer un compte?<br />
          Même si on vous dit qu'on ne fait rien de mal avec votre adresse email?<br />
          Utilisez le mode invité, mais il vous manquera des fonctionnalités.
        </p>
        <div className="self-end">
          <form method="POST" action="/?index">
            <input type="hidden" name="redirectTo" defaultValue={sp.get('redirectTo') ?? '/'} />
            <input type="hidden" name="sf" defaultValue={datas.sf} />
            <input type="hidden" name="cf" defaultValue={datas.cf} ref={ref} />
            <button tabIndex={0} className="btn btn-secondary join-item focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-double" type="submit" name="invite" value={"invite"}>Continuer</button>
          </form>
          {/* <button className="btn btn-warning join-item"><FontAwesomeIcon icon="times" /></button> */}
        </div>
      </div>
    </div>
  </div >
}

export default function Index() {
  return <BaseLayout
    className="relative sm:rounded-2xl"
    themeTogglerClassName={"? top-6 right-8"}
  >
    <IndexContent /></BaseLayout>
    ;
}
